import React from 'react';
import './PrivacyPolicy.css'; // We'll create a CSS file to style this component

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h2>Privacy Policy</h2>
            <p>
                Coffee is strongly committed to your right to privacy. This section covers Coffee's privacy policy that highlights how we handle personally identifiable information that Coffee collects when you are on our Website or App. Please read this privacy policy carefully. All information covered by Coffee's privacy policy will continue to be protected under Coffee's privacy policy irrespective of any change in your membership status in the future. By using the Coffee Website or App, you accept all practices described in this privacy policy.
            </p>

            <h3>Why do we collect personal information?</h3>
            <p>
                In this Privacy Policy, your "Personal Data" means information or pieces of information that could allow you to be identified. We process your personal data for us to be able to carry out our contractual commitments towards you. We use this information to assist you in your search for suitable connections in terms of age, gender, height, interests etc. In addition, we process Personal Data to comply with legal obligations. We also process your Personal Data for allowing other users to view your profile on Coffee, conducting an analysis of your browsing pattern and history, and allowing our access, payment, and storage business partners to process your Personal Data.
            </p>

            <p>
                Sometimes we need your consent to process Personal Data, such as for sending you newsletters and promotions. Before you can become a user we also ask for your explicit consent so that we can:
            </p>
            <ul>
                <li>Place tracking cookies</li>
                <li>Process your profile information</li>
                <li>Process data that you enter about your partner preferences</li>
                <li>Process photographs of you</li>
                <li>Process details of your work and interests</li>
                <li>Process your bio and other textual content added to your profile</li>
                <li>Process your geolocation</li>
                <li>Offer you services mentioned in this Privacy Policy that require an act of consent from you</li>
            </ul>

            <p>
                You have the right to withdraw your consent at any time for processing your location; however, this will not affect any of the prior processing of your Personal Data.
            </p>

            <h3>Which personal information do we process?</h3>
            <p>
                You will be asked to register before you can use Coffee. In the process, you will be asked for your name, AppleID, Phone Number, etc. After registering, you can create your personal Coffee account ("Your Account"). In this respect, you are invited to provide us with your name, pictures of you, a description of yourself, details about your interests, university, work, and basic information about yourself (such as your age, height, religion, ethnicity, place of residence etc). We will process this data.
            </p>

            <p>
                You can create Your Account through Sign In with Apple or your Phone Number. By using these login methods, you allow the platform to share with us the (personal) data you have shared with them – such as your name, email, gender, date of birth, pictures, likes, friend information etc. We will process this data.
            </p>
            <h3>Video Verification</h3>
            <p>
                Video Verification is an optional process that allows you to show that the photos on your profile look like you. After successful Video Verification, you’ll get a new badge on your profile to show people that you have completed the Video Verification process.
            </p>
            <p>
                With Video Verification, you will also be able to see whether other Coffee profiles have completed the Video Verification process. That way you can make more informed decisions about who you interact with on Coffee.
            </p>

            <h3>How does Video Verification work?</h3>
            <p>
                Video Verification is as simple as taking a video selfie. You will receive "Verified" status if the person in your video selfie passes both the Liveness Check and 3D Face Authentication components of the process.
            </p>
            <p>
                The Liveness Check detects the face in your video and helps determine whether the video was taken by a real, live person, and that it was not digitally altered or manipulated. 3D Face Authentication detects your face in your video selfie and your profile photos, and uses facial geometry data to generate a unique number, or facial geometry “template.” These templates are used to help check whether the person in your video selfie is the same person as in your profile photos.
            </p>
            <p>
                Your facial geometry data, which may be considered biometric data in some jurisdictions, is retained for the lifetime of your account for the purposes set out below in ‘How is my Photo Verification Information used’. Your video selfie is not added to your profile, but we retain one screenshot from your video selfie for the purposes of auditing and managing this feature.
            </p>

            <h3>How is my Video Verification Information used?</h3>
            <p>
                Your facial geometry data is only used for the purposes of Verification. Your video selfie itself is not stored, and is deleted immediately after you complete Video Verification. The screenshot we take from your video selfie is used only for the purposes of auditing and managing this feature.
            </p>
            <p>
                To perform the Verification process, we store and use your facial geometry data and the screenshot on our Amazon Web Services (AWS) instances. This information and screenshots are deleted from our AWS instances in the timeframes laid out below, under ‘What will you keep after Photo Verification is complete?’.
            </p>

            <h3>What will you keep after Photo Verification is complete?</h3>
            <p>
                Your facial geometry information is retained after the initial Photo Verification so that we can continue to provide the Photo Verification service to you as you change and update your profile photos over time. To do so, your facial geometry data is retained for the lifetime of your account, and is deleted from our systems within 30 days after the closure of your account, in accordance with our Privacy Policy.
            </p>
            <p>
                We will also keep one screenshot from the video selfie process. We retain this information for the life of your account to audit and manage our Verification feature. We’ll also keep the results of the Photo Verification process (i.e., successful or not) until you close your Coffee account, or alternatively, until your Coffee account is closed after two years of inactivity. As stated above, we do not keep your video selfie, which is deleted after you complete the Verification process.
            </p>

            <h3>How do I delete my Verification Information?</h3>
            <p>
                Your Verification Information includes your facial geometry data (which is the facial ‘template’ used to complete the Photo Verification check) and your video selfie screenshot. Please see below for information on how to delete these two types of data.
            </p>

            <p>
                <strong>Facial Geometry Data:</strong> You can delete your facial geometry data by deleting your Coffee account through the app’s settings page.
            </p>

            <p>
                <strong>Video Selfie Screenshots:</strong> You can request deletion of the screenshot from your video selfie by writing to saharsh@freetail.in. In any event, this information is deleted upon account closure, in accordance with our Privacy Policy.
            </p>


            <h3>How do we use your personal data?</h3>
            <p>
                We will only share your Personal Data with other users, and only what is necessary to assess if another user could be interesting for you – which will be clarified below.
            </p>
            <p>
                We will only disclose your Personal Data to third parties if there is a legal obligation to do so by a statutory authority from the appropriate jurisdiction. We do not sell your Personal Data to third parties.
            </p>

            <h3>Inviting friends</h3>
            <p>
                If you refer a friend to Coffee, you will send an email message, Whatsapp message, SMS, Messenger message to the contact details you submitted to us. We won't share these details with anyone else. By choosing to invite your friend to join Coffee, you confirm that your friend is happy to receive the invitation. You also hereby agree to indemnify and hold Coffee harmless from all claims arising out of your messages to any person through Coffee. You must and will not use our invite friends feature to send spam.
            </p>

            <h3>How do we protect your personal data?</h3>
            <p>
                We try our best to ensure that your Personal Data is protected and secure from unauthorized or unlawful access, alteration, disclosure, use, or destruction. We encrypt our services using SSL, the data is accessible only through a secret password and digital signatures, and our employees only have access to your data on a need-to-know basis. Unfortunately, the transmission of information via the internet is not always completely secure. Although we take all reasonable measures, we cannot guarantee the security of the data you transmit to our Website or App; any transmission is at your own risk.
            </p>

            <h3>How long do we retain your personal data?</h3>
            <p>
                Your Personal Data is retained no longer than strictly necessary or as long as allowed by law, whichever is shorter. In the event that you have not logged in to your profile for five years, your Personal Data may be erased from our records. Of course, you can always contact our support team through your registered email address with a request to delete your data from our records.
            </p>

            <h3>Changes to this privacy policy</h3>
            <p>
                We are constantly looking for new ways to improve Coffee and we may unilaterally update this Privacy Policy from time to time. When there is a significant change in this Privacy Policy, you will see a pop-up in the app which you will have to accept before you can continue to use Coffee.
            </p>

            <h3>Storage and transfer of your personal data</h3>
            <p>
                Your personal data may be hosted by our storage partners in servers located in countries other than your country of residence.
            </p>
            <p>
                You can also view, edit or delete your Personal Data by logging in to your Coffee account. To prevent loss of data due to natural disasters and other such unforeseeable circumstances, we store your details as a backup for a safety retention window of up to three years following account deletion. During this period, account information will be retained although the account will of course not be visible on Coffee anymore.
            </p>
            <p>
                When you delete your account, your personal data is deleted after this retention window. We only retain your email address and phone number in order to be able to contact you with regard to payment matters. For internal use, we may store generic, non-personal data regarding your activity as a user (number of people invited, number of logins, term of your subscription, payment method, etc).
            </p>

            <h3>Who are we?</h3>
            <p>
                Coffee, the accompanying website, and app are owned by Freetail Technologies Private Limited, JUBILEE PARK, 14, JUBILEE PARK, Tollygunge, Kolkata, Kolkata, West Bengal, 700033.
            </p>
            <p>
                We reserve the right, at our discretion, to make changes to the terms of use and privacy policy at any time. The most updated version is available on our App and Website at all times. Please check the terms of use and privacy policy periodically for changes as all modifications are effective upon publication. Continued use of the Coffee app and website after revision indicates the user's acceptance of the terms of use and privacy policy.
            </p>
            <p>
                In case you come across suspicious activity, immediately notify us at saharsh@freetail.in.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
