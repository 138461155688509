import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import './ContactUsForm.css'; // Assuming you have a separate CSS file for styling

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    issue: "",
  });

  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, phoneNumber, issue } = formData;

    if (!name || !issue) {
      setError("Name and Issue fields are mandatory.");
      return;
    }
    if (!email && !phoneNumber) {
      setError("Either phone number or email must be provided.");
      return;
    }

    setError(""); // Clear errors
    console.log("Form data submitted:", formData);
    alert("Thank you for contacting us! We will get back to you soon.");
  };

  // Function to navigate back
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="contact-container-desktop">
      <h2 className="heading-desktop">📞 Contact Us</h2>
      <form onSubmit={handleSubmit} className="form-desktop">
        <div className="input-with-label-desktop">
          <label className="label-desktop">Name (Required) ✍️:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input-desktop"
            required
          />
        </div>

        <div className="input-with-label-desktop">
          <label className="label-desktop">Email 📧:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="input-desktop"
          />
        </div>

        <div className="input-with-label-desktop">
          <label className="label-desktop">Phone Number 📱:</label>
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="input-desktop"
          />
        </div>

        <div className="input-with-label-desktop">
          <label className="label-desktop">Issue (Required) 📝:</label>
          <textarea
            name="issue"
            value={formData.issue}
            onChange={handleChange}
            className="textarea-desktop"
            required
          ></textarea>
        </div>

        {error && <p className="error-text-desktop">{error}</p>}

        {/* Buttons Wrapper */}
        <div className="buttons-wrapper">
          <button type="submit" className="button3-desktop">Submit</button>
          <button type="button" className="button-back-desktop" onClick={handleBack}>Back</button>
        </div>
      </form>

      <div className="contact-info-desktop">
        <h3>Our Contact Information</h3>
        <p>📧 Email: saharsh@freetail.in</p>
        <p>📞 Phone: +91 86974 69301</p>
        <p>📍 Address: 14, JUBILEE PARK, GROUND FLOOR, Tollygunge, Kolkata, Kolkata-700033, West Bengal</p>
      </div>
    </div>
  );
};

export default ContactUsForm;
